import {
	Add,
	ArrowBack,
	SubdirectoryArrowRight,
	BarChart,
	Block,
	Cake,
	CalendarToday,
	ArrowDropDown,
	ArrowDropUp,
	Check,
	CheckCircle,
	CircleOutlined,
	Close,
	Cancel,
	Edit,
	ErrorOutline,
	ExpandMore,
	Info,
	KeyboardArrowDown,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LocationOn,
	Logout,
	Input,
	Output,
	SupervisedUserCircle,
	Phone,
	PieChart,
	QuestionMark,
	Remove,
	Search,
	People,
	Visibility,
	VisibilityOff,
} from "@mui/icons-material";
import { styled } from "@mui/joy";
import { cloneElement, FC, SVGProps } from "react";

import Logo from "../../../../assets/images/Logo.svg";
import FolderOpen from "../../../../assets/images/folder_open.svg";
import AddDocumentIcon from "../../../../assets/images/icon-add-document.svg";
import AnalyzeIcon from "../../../../assets/images/icon-analyze.svg";
import IconWhite from "../../../../assets/images/icon-white.svg";
import PanelBtn from "../../../../assets/images/panel_btn.svg";

enum IconTypes {
	MATERIAL = "MATERIAL",
	SVG = "SVG",
}

type SvgComponent = FC<SVGProps<SVGSVGElement>>;
interface IconSpecMaterial {
	Component: JSX.Element;
	type: IconTypes.MATERIAL;
}
interface IconSpecSVG {
	Component: SvgComponent;
	type: IconTypes.SVG;
}

/** Map icons to their visual asset */
const SvgMap = {
	add: {
		Component: <Add />,
		type: IconTypes.MATERIAL,
	},
	addDocument: {
		Component: AddDocumentIcon as SvgComponent,
		type: IconTypes.SVG,
	},
	analyze: {
		Component: AnalyzeIcon as SvgComponent,
		type: IconTypes.SVG,
	},
	arrowBack: {
		Component: <ArrowBack />,
		type: IconTypes.MATERIAL,
	},
	arrowSubRight: {
		Component: <SubdirectoryArrowRight />,
		type: IconTypes.MATERIAL,
	},
	barChart: {
		Component: <BarChart />,
		type: IconTypes.MATERIAL,
	},
	block: {
		Component: <Block />,
		type: IconTypes.MATERIAL,
	},
	cake: {
		Component: <Cake />,
		type: IconTypes.MATERIAL,
	},
	calendar: {
		Component: <CalendarToday />,
		type: IconTypes.MATERIAL,
	},
	caretDown: {
		Component: <ArrowDropDown />,
		type: IconTypes.MATERIAL,
	},
	caretUp: {
		Component: <ArrowDropUp />,
		type: IconTypes.MATERIAL,
	},
	checkmark: {
		Component: <Check />,
		type: IconTypes.MATERIAL,
	},
	checkmarkFilled: {
		Component: <CheckCircle />,
		type: IconTypes.MATERIAL,
	},
	circle: {
		Component: <CircleOutlined />,
		type: IconTypes.MATERIAL,
	},
	close: {
		Component: <Close />,
		type: IconTypes.MATERIAL,
	},
	closeFilled: {
		Component: <Cancel />,
		type: IconTypes.MATERIAL,
	},
	edit: {
		Component: <Edit />,
		type: IconTypes.MATERIAL,
	},
	errorOutline: {
		Component: <ErrorOutline />,
		type: IconTypes.MATERIAL,
	},
	expand: {
		Component: <ExpandMore />,
		type: IconTypes.MATERIAL,
	},
	folderOpen: {
		Component: FolderOpen as SvgComponent,
		type: IconTypes.SVG,
	},
	iconWhite: {
		Component: IconWhite as SvgComponent,
		type: IconTypes.SVG,
	},
	information: {
		Component: <Info />,
		type: IconTypes.MATERIAL,
	},
	keyboardArrowDown: {
		Component: <KeyboardArrowDown />,
		type: IconTypes.MATERIAL,
	},
	keyboardArrowLeft: {
		Component: <KeyboardArrowLeft />,
		type: IconTypes.MATERIAL,
	},
	keyboardArrowRight: {
		Component: <KeyboardArrowRight />,
		type: IconTypes.MATERIAL,
	},
	location: {
		Component: <LocationOn />,
		type: IconTypes.MATERIAL,
	},
	logo: {
		Component: Logo as SvgComponent,
		type: IconTypes.SVG,
	},
	logout: {
		Component: <Logout />,
		type: IconTypes.MATERIAL,
	},
	movInput: {
		// TODO
		Component: <Input />,
		type: IconTypes.MATERIAL,
	},
	movOutput: {
		Component: <Output />,
		type: IconTypes.MATERIAL,
	},
	panelButton: {
		Component: PanelBtn as SvgComponent,
		type: IconTypes.SVG,
	},
	peopleInCircle: {
		Component: <SupervisedUserCircle />,
		type: IconTypes.MATERIAL,
	},
	phone: {
		Component: <Phone />,
		type: IconTypes.MATERIAL,
	},
	pieChart: {
		Component: <PieChart />,
		type: IconTypes.MATERIAL,
	},
	questionMark: {
		Component: <QuestionMark />,
		type: IconTypes.MATERIAL,
	},
	remove: {
		Component: <Remove />,
		type: IconTypes.MATERIAL,
	},
	search: {
		Component: <Search />,
		type: IconTypes.MATERIAL,
	},
	userMultiple: {
		Component: <People />,
		type: IconTypes.MATERIAL,
	},
	view: {
		Component: <Visibility />,
		type: IconTypes.MATERIAL,
	},
	viewOff: {
		Component: <VisibilityOff />,
		type: IconTypes.MATERIAL,
	},
} as const satisfies Record<string, IconSpecMaterial | IconSpecSVG>;

/** Available icon name */
export type IconNameKey = keyof typeof SvgMap;
/** "Enum" of icons */
export const IconName = Object.fromEntries(
	// FIXME: to remove
	Object.keys(SvgMap).map(key => [key, key]),
) as { [K in IconNameKey]: K };

/** Icon props */
export interface IconProps {
	/** The icon color, in a hex, rgb, rgba, hsl, hsla, etc. format (default: inherit) */
	color?: string;
	/** The icon image to use, from IconName enum */
	name: IconNameKey;
	/** The icon size, as a number in pixels (default: 16) */
	size?: number;
}

/**
 * Icon component
 *
 * @param props Props to generate the component
 */
export const Icon = (props: IconProps): JSX.Element => {
	const { color = "inherit", name, size = 16 } = props;
	const { Component, type } = SvgMap[name];

	if (type !== IconTypes.SVG) {
		return cloneElement(Component, {
			"data-testid": `ui/icons/${name}`,
			style: { color, fontSize: `${size}px` },
		});
	}

	return (
		<IconWrapper
			color={color}
			data-testid={`ui/icons/${name}`}
			height={`${size}px`}
		>
			<Component />
		</IconWrapper>
	);
};

const IconWrapper = styled("div")<{ color: string; height: string }>`
	fill: ${props => props.color};
	height: ${props => props.height};

	svg {
		height: 100%;
	}
`;
