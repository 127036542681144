import { OmitKnown } from "@nna/core";
import { Router } from "next/router";
import { Query, Route } from "nextjs-routes";
import { Entry } from "~/common";
import { AuthPasswordSet } from "~/common/auth";
import { PlanModel } from "~/common/plan";
import { PlanDto } from "~/common/plan/dtos";

/** Single Route meta data */
export interface RouteMeta<R extends Route = Route> {
	/** Determine, with parameters, if the current route is active */
	isActive?: (...params: never[]) => boolean;
	/** Get a {@link UrlObject} to use with the Next router */
	url: (...params: never[]) => R;
}

/** Recursive structure for route definition */
export type RouteDict = RouteMeta | { [K: string]: RouteDict };

/** Known pathnames */
export type RoutePathname = Route["pathname"];

/**
 * Creates a base {@link RouteMeta} for a given page (routePath)
 *
 * @param routePath the pathname to the page
 * @param completeUrl complete {@link RouteMeta.url} without the need to specify the pathname
 * @returns {RouteMeta} base for the given routePath
 */
function createBaseRouteMeta<
	const P extends RoutePathname,
	const URL extends (
		...params: never[]
	) => OmitKnown<Extract<Route, { pathname: P }>, "pathname">,
>(routePath: P, completeUrl: URL) {
	return {
		isActive: (route: Router["route"]) => route.startsWith(routePath),
		// @ts-expect-error -- annoying conversion from `never[]` to the parameters
		url: (...params: Parameters<URL>) => ({
			...completeUrl(...params),
			pathname: routePath,
		}),
	} as const satisfies RouteMeta<Extract<Route, { pathname: P }>>;
}

const planBasePath = "/plans" satisfies RoutePathname;

/** Params for most of Entry routes */
export interface PlanNEntryRouteParams {
	/** Param for {@link EntryDto} */
	entryId: Entry.EntryDto["_id"];
	/** Param for {@link PlanDto} */
	planId: PlanDto["_id"];
}
/** Params for most of Entry/information/child routes */
export interface EntryChildRouteParams extends PlanNEntryRouteParams {
	/** Param for {@link Entry.ResidenceDto} */
	childId: Entry.ChildDto["_id"];
}
/** Params for most of Entry/information/child routes */
export interface EntryChildInterruptionRouteParams
	extends EntryChildRouteParams {
	/** Param for {@link Entry.ResidenceDto} */
	interruptionId: Entry.InterruptionDto["_id"];
}
/** Params for most of Entry/Information/Residence routes */
export interface EntryResidenceRouteParams extends PlanNEntryRouteParams {
	/** Param for {@link Entry.ResidenceDto} */
	residenceId: Entry.ResidenceDto["_id"];
}
/** Params for most of Entry/Recipe routes */
export interface EntryRecipeRouteParams extends PlanNEntryRouteParams {
	/** Param for {@link Entry.RecipeDto} */
	recipeId: Entry.RecipeDto["_id"];
}
/** Params for most of Entry/Recipe/Salary routes */
export interface EntryRecipeSalaryRouteParams extends EntryRecipeRouteParams {
	/** Param for {@link Entry.SalaryDto} */
	salaryId: Entry.SalaryDto["_id"];
}
/** Params for most of Entry/Expense/Current routes */
export interface EntryCurrentExpenseGroupRouteParams
	extends PlanNEntryRouteParams {
	/** Param for {@link Entry.CurrentDto} */
	currentId: Entry.CurrentDto["_id"];
}

/** Params for most of Entry/Account routes */
export interface EntryAccountRouteParams extends PlanNEntryRouteParams {
	accountId: Entry.AccountDto["_id"];
}
/** Params for most of Entry/Transferable routes */
export interface EntryTransferableRouteParams extends PlanNEntryRouteParams {
	transferableId: Entry.TransferableDto["_id"];
}
/** Params for most of Entry/Transferable/Closure routes */
export interface EntryTransferableClosureRouteParams
	extends EntryTransferableRouteParams {
	/** Param for {@link Entry.TransferableClosureDto} */
	closureId: Entry.TransferableClosureDto["_id"];
}

/** Params for most of Entry/Stock routes */
export interface EntryStockRouteParams extends PlanNEntryRouteParams {
	stockId: Entry.StockDto["_id"];
}

/** ... */
export interface EntryStockClosureRouteParams extends EntryStockRouteParams {
	/** Param for {@link Entry.LoanClosureDto} */
	closureId: Entry.StockClosureDto["_id"];
}

/** ... */
export interface EntryStockTransactionRouteParams
	extends EntryStockRouteParams {
	transactionId: Entry.StockTransactionDto["_id"];
}
/** ... */
export interface EntryPillar1RouteParams extends PlanNEntryRouteParams {
	pillar1Id: Entry.Pillar1Dto["_id"];
}

/** Params for most of Entry/Pillar2/Plan routes */
export interface EntryPillar2PlanRouteParams extends PlanNEntryRouteParams {
	pillar2PlanId: Entry.Pillar2PlanDto["_id"];
}
/** Params for most of Entry/Pillar2/Plan/Characteristics routes */
export interface EntryPillar2PlanCharacteristicsRouteParams
	extends EntryPillar2PlanRouteParams {
	characteristicsId: Entry.CharacteristicsDto["_id"];
}

/** Params for most of Entry/Pillar2/Affiliation routes */
export interface EntryPillar2AffiliationRouteParams
	extends PlanNEntryRouteParams {
	pillar2AffiliationId: Entry.Pillar2AffiliationDto["_id"];
}
/** Params for most of Entry/Pillar2/Affiliation routes */
export interface EntryPillar2AffiliationPlanRouteParams
	extends EntryPillar2AffiliationRouteParams {
	pillar2AffiliationPlanId: Entry.Pillar2AffiliationPlanDto["_id"];
}

/** Params for most of Entry/Pillar2/Affiliation routes */
export interface EntryPillar2VestedBenefitsRouteParams
	extends PlanNEntryRouteParams {
	vestedBenefitsId: Entry.VestedBenefitsDto["_id"];
}

/** Params for most of Entry/Pillar2/Affiliation routes */
export interface EntryPillar2VestedBenefitsClosureParams
	extends EntryPillar2VestedBenefitsRouteParams {
	closureId: Entry.VestedBenefitClosureDto["_id"];
}

/** Params for most of Entry/Pillar3/a routes */
export interface EntryPillar3ARouteParams extends PlanNEntryRouteParams {
	pillar3AId: Entry.Pillar3.A.DTOs.Dto["_id"];
}
/** Params for most of Entry/Pillar3/a/premium routes */
export interface EntryPillar3APremiumRouteParams
	extends EntryPillar3ARouteParams {
	premiumId: Entry.Pillar3.Common.Premium.DTOs.Dto["_id"];
}

/** Params for most of Entry/Pillar3/b routes */
export interface EntryPillar3BRouteParams extends PlanNEntryRouteParams {
	pillar3BId: Entry.Pillar3.B.DTOs.Dto["_id"];
}
/** Params for most of Entry/Pillar3/b/premium routes */
export interface EntryPillar3BPremiumRouteParams
	extends EntryPillar3BRouteParams {
	premiumId: Entry.Pillar3.Common.Premium.DTOs.Dto["_id"];
}

/** Params for most of Entry/Credit routes */
export interface EntryCreditRouteParams extends PlanNEntryRouteParams {
	creditId: Entry.CreditDto["_id"];
}
/** Params for most of Entry/Credit/Evolution routes */
export interface EntryCreditEvolutionRouteParams
	extends EntryCreditRouteParams {
	/** Param for {@link Entry.CreditEvolutionDto} */
	evolutionId: Entry.CreditEvolutionDto["_id"];
}
/** Params for most of Entry/Credit/Closure routes */
export interface EntryCreditClosureRouteParams extends EntryCreditRouteParams {
	/** Param for {@link Entry.CreditClosureDto} */
	closureId: Entry.CreditClosureDto["_id"];
}

/** Params for most of Entry/Loan routes */
export interface EntryLoanRouteParams extends PlanNEntryRouteParams {
	loanId: Entry.LoanDto["_id"];
}
/** Params for most of Entry/Loan/Evolution routes */
export interface EntryLoanEvolutionRouteParams extends EntryLoanRouteParams {
	/** Param for {@link Entry.LoanEvolutionDto} */
	evolutionId: Entry.LoanEvolutionDto["_id"];
}
/** Params for most of Entry/Loan/Closure routes */
export interface EntryLoanClosureRouteParams extends EntryLoanRouteParams {
	/** Param for {@link Entry.LoanClosureDto} */
	closureId: Entry.LoanClosureDto["_id"];
}

/** Params for Entry/Independents routes */
export interface EntryIndependentRouteParams extends PlanNEntryRouteParams {
	independentId: Entry.IndependentDto["_id"];
}
/** Params for most of Entry/Independents/OperatingAccount routes */
export interface EntryIndependentOperatingAccountRouteParams
	extends EntryIndependentRouteParams {
	operatingAccountId: Entry.OperatingAccountDto["_id"];
}

function transformToRouteQuery<K extends string>(
	params: Record<K, number | string>,
) {
	return Object.fromEntries(
		Object.entries<number | string>(params).map(([key, val]) => [
			key,
			val.toString(),
		]),
	) as Record<K, string>;
}

// Note: Only use `satisfies RouteDict` with routes that do not use `createBaseRouteMeta`
//	Or the typecheck is almost 8s slower (even more with linting)

/** All known page route */
export const ROUTES = {
	auth: {
		forgotPassword: { url: () => ({ pathname: "/auth/forgot-password" }) },
		login: { url: () => ({ pathname: "/auth/login" }) },
		setPassword: { url: () => ({ pathname: AuthPasswordSet.PATH }) },
	} satisfies RouteDict,
	customers: {
		...createBaseRouteMeta("/customers", (query: Query = {}) => ({
			query,
		})),
	},
	entry: {
		accounts: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/accounts/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			newFuture: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/accounts/new-future",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/accounts/[accountId]",
				(params: EntryAccountRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/accounts",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		credits: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/credits/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			newFuture: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/credits/new-future",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				evolutions: {
					new: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/credits/[creditId]/evolutions/new",
						(params: EntryCreditRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
					one: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/credits/[creditId]/evolutions/[evolutionId]",
						(params: EntryCreditEvolutionRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/credits/[creditId]",
					(params: EntryCreditRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/credits",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		expenses: {
			current: {
				duplicate: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/expenses/current/[currentId]/duplicate",
					(params: EntryCurrentExpenseGroupRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/expenses/current/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/expenses/current/[currentId]",
					(params: EntryCurrentExpenseGroupRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/expenses/current",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			exceptional: {
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/expenses/exceptional",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
		},
		independents: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/independents/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				operatingAccounts: {
					new: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/independents/[independentId]/operating-accounts/new",
						(params: EntryIndependentRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
					one: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/independents/[independentId]/operating-accounts/[operatingAccountId]",
						(
							params: EntryIndependentOperatingAccountRouteParams,
						) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/independents/[independentId]",
					(params: EntryIndependentRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/independents",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		information: {
			children: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/information/children/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/information/children/[childId]",
					(params: EntryChildRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			residences: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/information/residences/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/information/residences/[residenceId]",
					(params: EntryResidenceRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/information",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		loans: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/loans/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			newFuture: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/loans/new-future",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				evolutions: {
					new: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/loans/[loanId]/evolutions/new",
						(params: EntryLoanRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
					one: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/loans/[loanId]/evolutions/[evolutionId]",
						(params: EntryLoanEvolutionRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},

				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/loans/[loanId]",
					(params: EntryLoanRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/loans",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		pillar1: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/pillar1/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar1/[pillar1Id]",
					(params: EntryPillar1RouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/pillar1",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		pillar2: {
			affiliations: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/affiliations/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: {
					plans: {
						new: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar2/affiliations/[pillar2AffiliationId]/plans/new",
							(params: EntryPillar2AffiliationRouteParams) => ({
								query: transformToRouteQuery(params),
							}),
						),
						one: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar2/affiliations/[pillar2AffiliationId]/plans/[pillar2AffiliationPlanId]",
							(
								params: EntryPillar2AffiliationPlanRouteParams,
							) => ({
								query: transformToRouteQuery(params),
							}),
						),
					},

					...createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/pillar2/affiliations/[pillar2AffiliationId]",
						(params: EntryPillar2AffiliationRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/affiliations",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			plans: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/plans/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: {
					characteristics: {
						new: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar2/plans/[pillar2PlanId]/characteristics/new",
							(params: EntryPillar2PlanRouteParams) => ({
								query: transformToRouteQuery(params),
							}),
						),
						one: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar2/plans/[pillar2PlanId]/characteristics/[characteristicsId]",
							(
								params: EntryPillar2PlanCharacteristicsRouteParams,
							) => ({
								query: transformToRouteQuery(params),
							}),
						),
					},
					...createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/pillar2/plans/[pillar2PlanId]",
						(params: EntryPillar2PlanRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/plans",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			vestedBenefits: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/vested-benefits/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				newFuture: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/vested-benefits/new-future",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: {
					...createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/pillar2/vested-benefits/[vestedBenefitsId]",
						(params: EntryPillar2VestedBenefitsRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar2/vested-benefits",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
		},
		pillar3: {
			a: {
				newBank: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar3/a/new-bank",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				newBankFuture: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar3/a/new-bank-future",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				newInsurance: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar3/a/new-insurance",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: {
					premiums: {
						new: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar3/a/[pillar3AId]/premiums/new",
							(params: EntryPillar3ARouteParams) => ({
								query: transformToRouteQuery(params),
							}),
						),
						one: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar3/a/[pillar3AId]/premiums/[premiumId]",
							(params: EntryPillar3APremiumRouteParams) => ({
								query: transformToRouteQuery(params),
							}),
						),
					},

					...createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/pillar3/a/[pillar3AId]",
						(params: EntryPillar3ARouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar3/a",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			b: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar3/b/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: {
					premiums: {
						new: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar3/b/[pillar3BId]/premiums/new",
							(params: EntryPillar3BRouteParams) => ({
								query: transformToRouteQuery(params),
							}),
						),
						one: createBaseRouteMeta(
							"/plans/[planId]/entries/[entryId]/pillar3/b/[pillar3BId]/premiums/[premiumId]",
							(params: EntryPillar3BPremiumRouteParams) => ({
								query: transformToRouteQuery(params),
							}),
						),
					},

					...createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/pillar3/b/[pillar3BId]",
						(params: EntryPillar3BRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/pillar3/b",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
		},
		recipes: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/recipes/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				salaries: {
					new: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/recipes/[recipeId]/salaries/new",
						(params: EntryRecipeRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
					one: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/recipes/[recipeId]/salaries/[salaryId]",
						(params: EntryRecipeSalaryRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/recipes/[recipeId]",
					(params: EntryRecipeRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/recipes",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		stocks: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/stocks/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			newFuture: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/stocks/new-future",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				transactions: {
					new: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/stocks/[stockId]/transactions/new",
						(params: EntryStockRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
					one: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/stocks/[stockId]/transactions/[transactionId]",
						(params: EntryStockTransactionRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/stocks/[stockId]",
					(params: EntryStockRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/stocks",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		transferables: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/transferables/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/transferables/[transferableId]",
					(params: EntryTransferableRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/transferables",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},

		...createBaseRouteMeta(
			"/plans/[planId]/entries/[entryId]",
			(params: PlanNEntryRouteParams) => ({
				query: transformToRouteQuery(params),
			}),
		),
	},
	errors: {},
	home: { url: () => ({ pathname: "/" }) } satisfies RouteDict,
	plans: {
		children: {
			one: {
				url: (id: PlanModel["_id"]) => ({
					pathname: `${planBasePath}/[planId]`,
					query: { planId: id.toString() },
				}),
			},
			settings: createBaseRouteMeta(
				"/plans/[planId]/settings",
				(params: Pick<PlanNEntryRouteParams, "planId">) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		...createBaseRouteMeta(planBasePath, () => ({})),
	},
} as const;
