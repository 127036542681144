import { Mutation, Query, QueryKey } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { Router } from "next/router";
import { ErrorHttpBody } from "~/common/errors";

import { FetchError } from "./FetchError";
import { getErrorContent, getErrorDefault } from "./notifications";
import { BannerVariants } from "../../../features/ui-atoms/components/Banner";
import { showToast } from "../../../features/ui-atoms/services/showToast";
import { ROUTES } from "../../routes";

export const handleGenericMutationErrors = async (
	error: FetchError,
	variables: unknown,
	context: unknown,
	mutation: Mutation<unknown, unknown, unknown, unknown>,
	router: Router,
	t: TFunction<"translation", undefined>,
): Promise<void> => {
	const { status } = error.response;

	const errorBody = (await error.response.json()) as Partial<ErrorHttpBody>;
	const { message, title } = getErrorContent(t, status, errorBody);
	showToast({
		message,
		title,
		variant: BannerVariants.ERROR,
	});

	handleHTTPStatus(status, router);
};

export const handleGenericMutationSuccess = (
	_data: unknown,
	_variables: unknown,
	_context: unknown,
	_mutation: Mutation<unknown, unknown, unknown, unknown>,
	_router: Router,
	_t: TFunction<"translation", undefined>,
): void => {
	// We do nothing here for now.
};

export const handleGenericQueryErrors = async (
	error: Error,
	query: Query<unknown, unknown, unknown, QueryKey>,
	router: Router,
	t: TFunction<"translation", undefined>,
): Promise<void> => {
	if (!(error instanceof FetchError)) {
		showToast({
			...getErrorDefault(t),
			variant: BannerVariants.ERROR,
		});
		return;
	}

	const errorBody = (await error.response.json()) as Partial<ErrorHttpBody>;
	const { message, title } = getErrorContent(
		t,
		error.response.status,
		errorBody,
	);
	showToast({
		message,
		title,
		variant: BannerVariants.ERROR,
	});

	handleHTTPStatus(error.response.status, router);
};

export const handleHTTPStatus = (code: number, router: Router): void => {
	if (code === 401) {
		void router.push(ROUTES.auth.login.url().pathname);
	}
};
