import { TFunction } from "next-i18next";
import { AnyLogicError, ErrorHttpBody } from "~/common/errors";

/** User-friendly error content */
interface ErrorContent {
	message?: string;
	title: string;
}

/**
 * Default error content
 *
 * @param t Translation function
 * @returns The default error content
 */
export function getErrorDefault(t: TFunction): ErrorContent {
	return {
		message: t("errors.http._default.message"),
		title: t("errors.http._default.title"),
	};
}

/**
 * Function converting a FetchError to an ErrorContent that can be displayed to the user (e.g. in a Toast)
 *
 * @param t Translation function
 * @param status The HTTP status code
 * @param errorBody The body of the error response
 * @returns The content to display to the user
 */
export function getErrorContent(
	t: TFunction,
	status: number,
	errorBody: Partial<ErrorHttpBody>,
): ErrorContent {
	switch (status) {
		case 400:
			return {
				message: t("errors.http.400.message"),
				title: t("errors.http.400.title"),
			};
		case 401:
			return {
				message: t("errors.http.401.message"),
				title: t("errors.http.401.title"),
			};
		case 403:
			return {
				message: t("errors.http.403.message"),
				title: t("errors.http.403.title"),
			};
		case 422: {
			return errorBody.error
				? translateLogicError(t, errorBody.error)
				: getErrorDefault(t);
		}
	}

	return getErrorDefault(t);
}

/** Error content for logic errors (422) */
function translateLogicError(t: TFunction, error: AnyLogicError): ErrorContent {
	const { code, type } = error;

	switch (type) {
		case "auth":
			switch (code) {
				case "jwt_invalid_or_expired":
					return {
						message: t(
							"features.auth.logic-errors.jwt_invalid_or_expired.message",
						),
						title: t(
							"features.auth.logic-errors.jwt_invalid_or_expired.title",
						),
					};
				case "login_disabled":
					return {
						message: t(
							"features.auth.logic-errors.login_disabled.message",
						),
						title: t(
							"features.auth.logic-errors.login_disabled.title",
						),
					};
				case "can_not_reset_while_active":
					return {
						message: t(
							"features.auth.logic-errors.can_not_reset_while_active.message",
						),
						title: t(
							"features.auth.logic-errors.can_not_reset_while_active.title",
						),
					};
			}
		// eslint-disable-next-line no-fallthrough -- doesn't detect that the switch above is exhaustive
		case "entry":
			switch (code) {
				case "update_on_deleted":
					return {
						message: t(
							"features.entry.logic-errors.update_on_deleted.message",
						),
						title: t(
							"features.entry.logic-errors.update_on_deleted.title",
						),
					};
			}
	}
}
