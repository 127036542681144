import {
	keepPreviousData,
	Mutation,
	MutationCache,
	MutationMeta,
	QueryCache,
	QueryClient,
	QueryMeta,
	UseMutationOptions,
	UseQueryOptions,
} from "@tanstack/react-query";
import { TFunction } from "i18next";
import { Router } from "next/router";

import {
	handleGenericMutationErrors,
	handleGenericMutationSuccess,
	handleGenericQueryErrors,
	handleHTTPStatus,
} from "./utils";
import { FetchError } from "./utils/FetchError";

export const apiRootPath = "/api/";

/** Interface to define the meta options for a mutation */
export interface CustomMutationMeta extends QueryMeta {
	onError?: (
		error: unknown,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown, unknown>,
		router: Router,
		t: TFunction<"translation", undefined>,
	) => void;
	onSuccess?: (
		data: unknown,
		variables: unknown,
		context: unknown,
		mutation: Mutation<unknown, unknown, unknown, unknown>,
		router: Router,
		t: TFunction<"translation", undefined>,
	) => void;
	skipDefaultErrorHandling?: boolean;
}

/** Custom options for useMutation */
export interface CustomUseMutationOptions<FrontEntity, FetchError, FrontBody>
	extends UseMutationOptions<FrontEntity, FetchError, FrontBody> {
	meta?: CustomMutationMeta;
}

/** Interface to define the meta options for a query */
export interface CustomQueryMeta extends MutationMeta {
	onError?: (
		error: unknown,
		query: unknown,
		router: Router,
		t: TFunction<"translation", undefined>,
	) => void;
	onSuccess?: (
		data: unknown,
		query: unknown,
		router: Router,
		t: TFunction<"translation", undefined>,
	) => void;
	skipDefaultErrorHandling?: boolean;
}

/** Custom options for useQuery */
export interface CustomUseQueryOptions<FrontEntity, FetchError>
	extends UseQueryOptions<FrontEntity, FetchError, FrontEntity> {
	meta?: CustomQueryMeta;
}

const defaultRetryMutation = 0;
const defaultRetryQuery = 0;

export const ReactQueryConfig = (
	router: Router,
	t: TFunction<"translation", undefined>,
): QueryClient =>
	new QueryClient({
		defaultOptions: {
			mutations: {
				retry: (failureCount: number, error: Error) => {
					handleHTTPStatus(
						(error as FetchError).response?.status,
						router,
					);
					return failureCount < defaultRetryMutation;
				},
			},
			queries: {
				// This avoid having empty data between requests
				placeholderData: keepPreviousData,
				retry: (failureCount: number, error: Error) => {
					handleHTTPStatus(
						(error as FetchError).response?.status,
						router,
					);
					return failureCount < defaultRetryQuery;
				},
				staleTime: 60 * 1000,
			},
		},
		mutationCache: new MutationCache({
			onError: (error, variables, context, mutation) => {
				if (mutation?.meta?.onError) {
					(mutation.meta as CustomMutationMeta).onError?.(
						error as FetchError,
						variables,
						context,
						mutation,
						router,
						t,
					);
				}

				// If skipDefaultErrorHandling is not explicitly set to true, we run the default error handler
				if (!mutation?.meta?.skipDefaultErrorHandling) {
					void handleGenericMutationErrors(
						error as FetchError,
						variables,
						context,
						mutation,
						router,
						t,
					);
				}
			},
			onSuccess: (data, variables, context, mutation) => {
				if (mutation?.meta?.onSuccess) {
					(mutation.meta as CustomMutationMeta).onSuccess?.(
						data,
						variables,
						context,
						mutation,
						router,
						t,
					);
				} else {
					void handleGenericMutationSuccess(
						data,
						variables,
						context,
						mutation,
						router,
						t,
					);
				}
			},
		}),
		queryCache: new QueryCache({
			onError: (error, query) => {
				if (query?.meta?.onError) {
					(query.meta as CustomQueryMeta).onError?.(
						error as FetchError,
						query,
						router,
						t,
					);
				}

				// If skipDefaultErrorHandling is not explicitly set to true, we run the default error handler
				if (!query?.meta?.skipDefaultErrorHandling) {
					void handleGenericQueryErrors(error, query, router, t);
				}
			},
			onSuccess: (data, query) => {
				if (query?.meta?.onSuccess) {
					(query.meta as CustomQueryMeta).onSuccess?.(
						data,
						query,
						router,
						t,
					);
				}
			},
		}),
	});
